import { getFormProps, getInputProps, useForm } from '@conform-to/react'
import { getZodConstraint, parseWithZod } from '@conform-to/zod'
import { json, type ActionFunctionArgs, type LoaderFunctionArgs, type MetaFunction } from '@remix-run/node'
import { Form, useActionData, useSearchParams } from '@remix-run/react'
import { HoneypotInputs } from 'remix-utils/honeypot/react'
import { z } from 'zod'
import { Button } from '#app/components/buttons/button.tsx'
import { Flex } from '#app/components/layout/flex.tsx'
import { VisuallyHidden } from '#app/components/layout/visually-hidden.tsx'
import { A } from '#app/components/typography/a.tsx'
import { H1 } from '#app/components/typography/h1.tsx'
import { P } from '#app/components/typography/p.tsx'
import { Input } from '#app/old/components/data-entry/input.tsx'
import { Label } from '#app/old/components/data-entry/label.tsx'
import { GeneralErrorBoundary } from '#app/old/components/error-boundary.tsx'
import { CenteredFormContainer } from '#app/old/patterns/centered-form-container.tsx'
import { login, requireAnonymous } from '#app/utils/auth.server.ts'
import { checkHoneypot } from '#app/utils/honeypot.server.ts'
import { useIsPending } from '#app/utils/misc.tsx'
import { mergeMeta } from '#app/utils/seo.ts'
import { EmailSchema, PasswordSchema } from '#app/utils/user-validation.ts'
import { handleNewSession } from './login.server.ts'

const LoginFormSchema = z.object({
	email: EmailSchema,
	password: PasswordSchema,
	redirectTo: z.string().optional(),
})

export const meta: MetaFunction = mergeMeta(() => [{ title: 'Log in to VerveUI' }])

export async function loader({ request }: LoaderFunctionArgs) {
	await requireAnonymous(request)
	return json({})
}

export async function action({ request }: ActionFunctionArgs) {
	await requireAnonymous(request)
	const formData = await request.formData()
	checkHoneypot(formData)
	const submission = parseWithZod(formData, { schema: LoginFormSchema })
	if (submission.status !== 'success') {
		return json(submission.reply(), { status: submission.status === 'error' ? 400 : 200 })
	}
	const { email, password } = submission.value
	const session = await login({ email, password })
	if (session === null) {
		return json(submission.reply({ formErrors: ['Invalid email or password'] }))
	}

	//const { redirectTo } = submission.value
	const redirectTo = '/profile'
	return handleNewSession({ request, session, redirectTo })
}

export default function LoginPage() {
	const lastResult = useActionData<typeof action>()
	const isPending = useIsPending()
	const [searchParams] = useSearchParams()
	const redirectTo = searchParams.get('redirectTo')

	const [form, { email, password }] = useForm({
		id: 'login-form',
		constraint: getZodConstraint(LoginFormSchema),
		defaultValue: { redirectTo },
		lastResult,
		onValidate({ formData }) {
			return parseWithZod(formData, { schema: LoginFormSchema })
		},
		shouldRevalidate: 'onBlur',
	})

	return (
		<CenteredFormContainer>
			<H1 className="text-muted-700 text-center">Welcome back!</H1>
			<Flex orientation="vertical" gap="8">
				<Form method="POST" {...getFormProps(form)} className="contents">
					<HoneypotInputs />
					<VisuallyHidden>
						<Label htmlFor={email.id}>Email</Label>
					</VisuallyHidden>
					<Input
						aria-invalid={email.errorId ? true : undefined}
						aria-describedby={email.errorId}
						autoComplete="email"
						placeholder="Enter your email"
						{...getInputProps(email, { type: 'email' })}
					/>
					{email.errors?.map(e => <P key={e}>{e}</P>)}

					<VisuallyHidden>
						<Label htmlFor={password.id}>Password</Label>
					</VisuallyHidden>
					<Input
						aria-invalid={password.errorId ? true : undefined}
						aria-describedby={password.errorId}
						autoComplete="current-password"
						placeholder="Enter your password"
						{...getInputProps(password, { type: 'password' })}
					/>
					{password.errors?.map(e => <P key={e}>{e}</P>)}
					<Button variant="primary" size="md" className="w-full" /* status={isPending ? 'pending' : lastResult?.status ?? 'idle'}  */ type="submit" isDisabled={isPending}>
						{isPending ? 'Logging in' : 'Log in'}
					</Button>
					{form.errors?.map(e => <P key={e}>{e}</P>)}
					<Flex orientation="vertical" justify="between" items="center" className="mt-6">
						<A href="/forgot-password" prefetch="intent">
							Forgot password?
						</A>
						<Flex gap="6">
							<span className="text-muted-foreground">New here?</span>
							<A href={`/create-account${redirectTo ? `?redirectTo=${redirectTo}` : ''}`} prefetch="intent">
								Create an account
							</A>
						</Flex>
					</Flex>
				</Form>
			</Flex>
		</CenteredFormContainer>
	)
}

export function ErrorBoundary() {
	return <GeneralErrorBoundary />
}
